import request from '@/utils/request'

export function feedbackAdd(params) {
	return request({
		url: '/sys/feedback/add',
		method: 'post',
		data: params
	})
}

export function feedbackEdit(params) {
	return request({
		url: '/sys/feedback/edit',
		method: 'post',
		data: params
	})
}

export function feedbackList(params) {
	return request({
		url: '/sys/feedback/list',
		method: 'post',
		data: params
	})
}

export function feedbackBack(params) {
	return request({
		url: '/sys/feedback/back',
		method: 'post',
		params
	})
}

export function feedbackInfo(params) {
	return request({
		url: '/sys/feedback/info',
		method: 'post',
		params
	})
}