<template>
	<div>
		<Header></Header>
		<div class="common-header">
			<div class="search-healder">
				<div class="logo-box">
					<img @click="toUrl('/')" class="logo" src="../../image/index/logo.png" />
				</div>
				<div class="search">
					<input placeholder="请输入商品名称" v-model="name" />
					<div class="search-btn el-icon-search" @click="search()"> </div>
				</div>
			</div>
		</div>
		<div class="apply-content">
			<div class="step">
				<div class="form-box">
					<el-form ref="form" :model="form" label-width="120px" size="medium">
						<el-form-item label="联系电话">
							<el-input v-model="form.phone" placeholder="请输入您的联系电话"></el-input>
						</el-form-item>
						<el-form-item label="问题描述">
							<el-input type="textarea" :rows="4" v-model="form.content"
								placeholder="请详细描述问题,我们将尽快为您解答"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submit()"
								:loading="submitLoading">{{submitLoading?'提交中':'提交'}}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<Footer></Footer>

	</div>
</template>

<script>
	import {
		feedbackAdd
	} from '@/api/sys/feedback'
	export default {
		data() {
			return {
				name: '',
				form: {
					phone: '',
					content: ''
				},
				submitLoading: false
			};
		},
		created() {},
		methods: {
			search() {
				this.$router.push({
					path: '/item/search?name=' + this.name
				})
			},
			toUrl(url) {
				this.$router.push({
					path: url
				})
			},
			submit() {
				if (this.submitLoading) {
					return
				}
				var data = this.form
				if (!data.phone) {
					this.$message({
						message: '请输入您的联系电话',
						type: 'error'
					});
					return
				}
				if (!data.content) {
					this.$message({
						message: '请输入描述内容',
						type: 'error'
					});
					return
				}
				console.log(data)
				this.submitLoading = true
				feedbackAdd(data).then(res => {
					this.submitLoading = false
					this.$message({
						message: '问题已提交',
						type: 'success'
					});
					this.toUrl('/')
				})
			}
		},
	};
</script>

<style lang='scss'>
	.apply-content {
		width: 80%;
		margin-left: 10%;

		.step {
			width: 60%;
			margin-left: 20%;
			margin-top: 20px;
		}

		.form-box {
			margin-top: 20px;
		}
	}
</style>